
import { Options, Vue } from 'vue-class-component'
import { toDate } from '../utils/common'
@Options({
  components: {}
})
export default class Home extends Vue {
  toDate = toDate;

  timenow = this.toDate(new Date().getTime())
}
